import $ from 'jquery';

import './styles.scss';

interface CollapsibleCheckboxTreeOptions {
    checkParents: boolean;
    checkChildren: boolean;
    uncheckChildren: boolean;
    showButtons: boolean;
}

const defaults: CollapsibleCheckboxTreeOptions = {
    checkParents: true, // When checking a box, all parents are checked
    checkChildren: false, // When checking a box, all children are checked
    uncheckChildren: true, // When unchecking a box, all children are unchecked
    showButtons: false
};

$.fn.collapsibleCheckboxTree = function(options?: Partial<CollapsibleCheckboxTreeOptions>): JQuery {
    const settings = { ...defaults, ...options };

    return this.each(function (): void {
        // Add button
        if (settings.showButtons) {
            $(this).before(
                '<button id="expand">Раскрыть все</button>' +
                '<button id="collapse">Свернуть все</button>' +
                '<button id="default">По умолчанию</button>'
            );
        }

        // Hide all except top level
        $('ul', $(this)).addClass('hide');

        // Check parents if necessary
        if (settings.checkParents) {
            $('input:checked').parents('li').find('input[type=checkbox]:first').prop('checked', true);
        }

        // Check children if necessary
        if (settings.checkChildren) {
            $('input:checked').parent('li').find('input[type=checkbox]').prop('checked', true);
        }

        // Show checked and immediate children of checked
        $('li:has(input:checked) > ul', $(this)).removeClass('hide');

        // Add tree links
        $('li', $(this)).prepend('<span>&nbsp;</span>');
        $('li:has(> ul:not(.hide)) > span', $(this)).addClass('expanded').html('-');
        $('li:has(> ul.hide) > span', $(this)).addClass('collapsed').html('+');

        // Checkbox function
        $('input[type="checkbox"]', $(this)).on('click', function () {
            // If checking ...
            if ($(this).is(':checked')) {

                // Show immediate children  of checked
                $('> ul', $(this).parent('li')).removeClass('hide');

                // Update the tree
                $('> span.collapsed', $(this).parent('li')).removeClass('collapsed').addClass('expanded').html('-');

                // Check parents if necessary
                if (settings.checkParents) {
                    $(this).parents('li').find('input[type=checkbox]:first').prop('checked', true);
                }

                // Check children if necessary
                if (settings.checkChildren) {
                    $(this).parent('li').find('input[type=checkbox]').prop('checked', true);
                    // Show all children of checked
                    $('ul', $(this).parent('li')).removeClass('hide');
                    // Update the tree
                    $('span.collapsed', $(this).parent('li')).removeClass('collapsed').addClass('expanded').html('-');
                }
                // If unchecking...
            } else {
                // Uncheck children if necessary
                if (settings.uncheckChildren) {
                    $(this).parent('li').find('input[type="checkbox"]').prop('checked', false);
                    // Hide all children
                    $('ul', $(this).parent('li')).addClass('hide');
                    // Update the tree
                    $('span.expanded', $(this).parent('li')).removeClass('expanded').addClass('collapsed').html('+');
                }
            }
        });

        // Tree function
        $('li:has(> ul) span', $(this)).on('click', function () {
            // If was previously collapsed...
            if ($(this).is('.collapsed')) {
                // ... then expand
                $('> ul', $(this).parent('li')).removeClass('hide');
                // ... and update the html
                $(this).removeClass('collapsed').addClass('expanded').html('-');

                // If was previously expanded...
            } else if ($(this).is('.expanded')) {
                // ... then collapse
                $('> ul', $(this).parent('li')).addClass('hide');
                // and update the html
                $(this).removeClass('expanded').addClass('collapsed').html('+');
            }
        });
    });
};

// Initialize on document ready
window.addEventListener('DOMContentLoaded', () => {
    $('[data-checkboxtree]').collapsibleCheckboxTree();
});

import Typograf from 'typograf';

Typograf.addRule({
    name: 'common/other/removePhoneNumber',
    disabled: true,
    handler: function (text) {
        return text.replace(/\s+8-?9\d\d-?\d\d\d-?\d\d-?\d\d/g, '');
    }
});

Typograf.addRule({
    name: 'common/other/yearManufactured',
    disabled: true,
    handler(text) {
        return text
            .replace(/(\d{2}?\d{2})\s?г\.\s?в\./g, '$1 года выпуска');
    }
});

export default Typograf;

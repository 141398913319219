import Typograf from 'components/typograf';

const typograf = new Typograf({
    locale: ['ru', 'en-US'],
    enableRule: [
        'common/html/stripTags',
        'common/space/delLeadingBlanks'
    ],
    disableRule: [
        'ru/date/fromISO'
    ]
});

const typografInputField = (elmId: string): void => {
    const inputField = document.getElementById(elmId) as HTMLInputElement | null;

    if (!inputField) {
        throw `Поле "${elmId}" формы не найдено!`;
    }

    const typografExecute = (e): void => {
        e.currentTarget.value = typograf.execute(e.currentTarget.value);
    };

    inputField.onblur = typografExecute;
    inputField.onfocus = typografExecute;
};

export { typografInputField };

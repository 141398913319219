import { optimize } from 'svgo/lib/svgo';

const svgLogoField = (elmId: string): void => {
    const svgLogoInput = document.getElementById(elmId) as HTMLInputElement | null;

    if (!svgLogoInput) {
        throw `Поле "${elmId}" формы не найдено!`;
    }

    const svgLogoPreview = document.createElement('div');

    svgLogoPreview.style.marginTop = '1rem';
    svgLogoPreview.style.width = '200px';
    svgLogoPreview.style.border = '1px darkgray solid';

    svgLogoInput.parentNode?.append(svgLogoPreview);

    const uploadSVGLogoFromFile = (file) => {
        if (file.type === 'image/svg+xml') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                if (e.target && e.target.result) {
                    const result = e.target.result.toString();
                    const svg = atob(result.replace(/data:image\/svg\+xml;base64,/, ''));
                    svgLogoInput.value = optimizeSVG(svg);
                    updateSVGPreview();
                }
            };
        } else {
            svgLogoPreview.innerText = 'Необходимо загрузить логотип в формате SVG';
        }
    };

    const optimizeSVG = (svgString: string): string => {
        const value = optimize(svgString, {
            multipass: true,
            js2svg: {
                indent: 2,
                pretty: true,
            },
            plugins: [
                'cleanupAttrs',
                'cleanupEnableBackground',
                'cleanupIds',
                'cleanupNumericValues',
                'collapseGroups',
                'convertColors',
                'convertEllipseToCircle',
                'convertPathData',
                'convertShapeToPath',
                'convertStyleToAttrs',
                'convertTransform',
                'inlineStyles',
                'mergePaths',
                'mergeStyles',
                'minifyStyles',
                'moveElemsAttrsToGroup',
                'moveGroupAttrsToElems',
                'removeComments',
                'removeDesc',
                'removeDimensions',
                'removeDoctype',
                'removeEditorsNSData',
                'removeEmptyAttrs',
                'removeEmptyContainers',
                'removeEmptyText',
                'removeHiddenElems',
                'removeMetadata',
                'removeNonInheritableGroupAttrs',
                'removeTitle',
                'removeUnknownsAndDefaults',
                'removeUnusedNS',
                'removeUselessDefs',
                'removeUselessStrokeAndFill',
                'removeViewBox',
                'removeXMLNS',
                'removeXMLProcInst',
                'sortAttrs',
                'sortDefsChildren',
            ]
        });

        return value.data.trim();
    };

    const updateSVGPreview = () => {
        if (svgLogoInput.value) {
            svgLogoPreview.innerHTML = svgLogoInput.value;
            svgLogoPreview.style.display = 'block';
        } else {
            svgLogoPreview.innerHTML = '';
            svgLogoPreview.style.display = 'none';
        }
    };

    const onChangeSVGLogo = (e) => {
        svgLogoInput.value = optimizeSVG(e.currentTarget.value);
    };

    const onDropSVGLogo = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const dt = e.dataTransfer;
        const files = dt?.files;

        if (files?.length === 1) {
            uploadSVGLogoFromFile(files[0]);
        }
    };

    svgLogoInput.onchange = onChangeSVGLogo;
    svgLogoInput.onkeyup = updateSVGPreview;
    svgLogoInput.ondrop = onDropSVGLogo;

    updateSVGPreview();
};

export { svgLogoField };
